import * as React from 'react'
import SideNavAction from '../actions/side-nav'
import cx from 'classnames'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

interface Props {
  className?: string
}

interface State {}

export default class SideNavToggleButton extends React.Component <Props, State>{
  constructor (props: Props) {
    super(props)
  }

  onClick = () => {
    SideNavAction.toggle()
  }

  render () {
    const { className, children } = this.props

    return (
      <OverlayTrigger placement='bottom'
      overlay={
        <Tooltip id='test'>
          Toggle inventory
        </Tooltip>
      }
      >
        <a onClick={this.onClick} className={cx('side-nav-toggle-button', className)}>
          {children}
        </a>
      </OverlayTrigger>
    )
  }
}
