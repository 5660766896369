import {cloneDeep} from 'lodash'
import Category from '../types/category'
import Gear from '../types/gear'
import PackedGear from '../types/packed-gear'


export function weightWithUnit (gramWeight: number, toUnit: 'g' | 'kg' | 'lb' | 'oz') {
  switch (toUnit) {
    case 'g':
      return gramWeight
    case 'kg':
      return Math.round(gramWeight / 1000 * 100) / 100
    case 'lb':
      return Math.round(gramWeight / 453.592 * 100) / 100
    case 'oz':
      return Math.round(gramWeight / 28.3495)
  }
}

export function gearWithId (allGears: Gear[], id: string): Gear | null {
  let res: Gear | undefined = allGears.find((gear) => {
    return gear.id === id
  })
  if (!res) return null
  return res
}

export function packedGearWithId (gearId: string, category: Category): PackedGear | null {
  const result = category.data.gears.find((packedGear) => {
    return packedGear.id === gearId
  })
  return result ? result : null
}

export function totalWeightInCategory (gears: Gear[], category: Category): number {
  let weight = 0
  category.data.gears.forEach ((packInfo) => {
    const gear = gearWithId(gears, packInfo.id)
    if (!gear) return
    let w = gear!.data.weight * packInfo.amount
    weight += w
  })
  return weight
}

export function baseWeightInCategory (gears: Gear[], category: Category): number {
  let weight = 0
  category.data.gears.forEach ((packInfo) => {
    const gear = gearWithId(gears, packInfo.id)
    if (!gear) return
    let w = gear!.data.weight * packInfo.amount
    if (packInfo.type === null) {
      weight += w
    }
  })
  return weight
}

export function wornWeightInCategory (gears: Gear[], category: Category): number {
  let weight = 0
  category.data.gears.forEach ((packInfo) => {
    const gear = gearWithId(gears, packInfo.id)
    if (!gear) return
    let w = gear!.data.weight * packInfo.amount
    if (packInfo.type === 'worn') {
      weight += w
    }
  })
  return weight
}

export function consumableWeightInCategory (gears: Gear[], category: Category): number {
  let weight = 0
  category.data.gears.forEach ((packInfo) => {
    const gear = gearWithId(gears, packInfo.id)
    if (!gear) return
    let w = gear!.data.weight * packInfo.amount
    if (packInfo.type === 'consumable') {
      weight += w
    }
  })
  return weight
}

export function packWeightInCategory (gears: Gear[], category: Category): number {
  let weight = 0
  return baseWeightInCategory(gears, category) + consumableWeightInCategory(gears, category)
}

export function totalWeightInCategories (gears: Gear[], categories:  Category[]): number {
  let weight = 0
  categories.forEach((category) => {
    const categoryWeight = totalWeightInCategory(gears, category)
    weight += categoryWeight
  })
  return weight
}

export function baseWeightInCategories (gears: Gear[], categories:  Category[]): number {
  let weight = 0
  categories.forEach((category) => {
    const categoryWeight = baseWeightInCategory(gears, category)
    weight += categoryWeight
  })
  return weight
}

export function wornWeightInCategories (gears: Gear[], categories:  Category[]): number {
  let weight = 0
  categories.forEach((category) => {
    const categoryWeight = wornWeightInCategory(gears, category)
    weight += categoryWeight
  })
  return weight
}

export function consumableWeightInCategories (gears: Gear[], categories:  Category[]): number {
  let weight = 0
  categories.forEach((category) => {
    const categoryWeight = consumableWeightInCategory(gears, category)
    weight += categoryWeight
  })
  return weight
}

export function packWeightInCategories (gears: Gear[], categories: Category[]): number {
  let weight = 0
  if (!gears) return weight
  return baseWeightInCategories(gears, categories) + consumableWeightInCategories(gears, categories)
}

export function packIdFromUrl (url: string): string {
  let array = url.match(/([^\/.]+)/g)
  return array![array!.length - 1]
}
