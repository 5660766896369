import { EventEmitter2 as EventEmitter } from 'eventemitter2'
import firebase from 'firebase'
import dispatcher from '../dispatcher'
import Gear from '../types/gear'
const db = firebase.firestore()

export class _GearsStore extends EventEmitter {
  private _gears: Gear[] = []
  dispatchToken: string | null = null

  gears() {
    return this._gears
  }

  _initGear(doc: any) {
    return {
      id: doc.id,
      data: doc.data ()
    }
  }

  async load() {
    const user = firebase.auth().currentUser
    if (!user) return
    const data = await db.collection('gears').where('user_id', '==', user.uid).orderBy('created_at', 'desc').get()
    let gears = data.docs.map((doc) => {
      return this._initGear(doc)
    })
    this._gears = gears
  }

  async loadWithUserId(userId: string) {
    if (!userId) return
    const data = await db.collection('gears').where('user_id', '==', userId).orderBy('created_at', 'desc').get()
    const gears = data.docs.map((doc) => {
      return this._initGear(doc)
    })
    this._gears = gears
  }
}

const GearsStore = new _GearsStore()
GearsStore.dispatchToken = dispatcher.register(async (payload: any) => {
  if (payload.type === 'change-gears') {
    await GearsStore.load()
    GearsStore.emit('change', GearsStore)
  } else if (payload.type === 'load-with-userid') {
    await GearsStore.loadWithUserId(payload.userId)
    GearsStore.emit('change', GearsStore)
  } else if (payload.type === 'duplicate-gears') {
    GearsStore.emit('duplicate-gears')
  }
})

export default GearsStore
