import React from 'react'
import Category from '../../types/category'
import Gear from '../../types/gear'
import Pack from '../../types/pack'
import DragStore from '../../stores/drag'
import '../../scss/pack-category.scss'
import cx from 'classnames'
import CategoryHeader from './category-header'
import TableRow from './table-row'
import * as Helper from '../../helpers/helper'
import GearCreateForm from '../gear-create-form'
// actions
import DragAction from '../../actions/drag'
import CategoriesAction from '../../actions/categories'

interface Props {
  allGears: Gear[]
  pack: Pack
  category: Category
  setting: any
  editable: boolean
}

interface States {
  dragging: boolean
  dragenter: boolean
  draggingRow: boolean
}

export default class PackCategory extends React.Component <Props, States> {

  constructor(props: Props) {
    super(props)
    this.state = {
      dragging: false,
      dragenter: false,
      draggingRow: false
    }
  }

  componentDidMount () {
    DragStore.on('drag-start', this.onDragStartFromInventory)
    DragStore.on('drag-end', this.onDragEndFromInventory)
  }

  componentWillUnmount () {
    DragStore.off('drag-start', this.onDragStartFromInventory)
    DragStore.off('drag-end', this.onDragEndFromInventory)
  }

  onClickNewGearButton = () => {
  }

  onChangeOrder = (from: number, to: number) => {
    let category = {...this.props.category}
    const target = category.data.gears[from]
    category.data.gears.splice(from, 1)
    category.data.gears.splice(to, 0, target)
    CategoriesAction.update(category, this.props.pack.id)
  }

  /* D&D actions to category from gear's inventory */
  onDragStartFromInventory = () => {
    this.setState({ dragging: true })
  }

  onDragEndFromInventory = () => {
    this.setState({ dragging: false })
  }

  onDragOverCategory = (e: any) => {
    e.preventDefault()
  }

  onDragEnterToCategory = (e: any) => {
    if (this.state.dragenter) return
    this.setState({ dragenter: true })
    e.preventDefault()
  }

  onDragLeaveCategory = (e: any) => {
    this.setState({ dragenter: false })
  }

  onDropToCategory = (e: any) => {
    const { category, allGears, pack } = this.props
    this.setState({ dragenter: false })
    const gear = JSON.parse(e.dataTransfer.getData('gear'))
    category.data.gears.push({
      id: gear.id,
      amount: 1,
      type: null
    })
    CategoriesAction.update(category, pack.id)
  }

  render() {
    const { allGears, pack, category, editable, setting } = this.props
    return (
      <div
        className='pack-category'
        key={category.id}
      >
        <CategoryHeader pack={pack} category={category} editable={editable} setting={setting} allGears={allGears}/>
        {
          category.data.gears.map((packedGear, index) => {
            if (editable) {
              return (
                <Draggable
                  index={index}
                  onChangeOrder={this.onChangeOrder}
                  key={packedGear.id}
                  >
                  <TableRow pack={pack} category={category} gear={Helper.gearWithId(allGears, packedGear.id)!} editable={editable} setting={setting} />
                </Draggable>
              )
            }
            return (
              <TableRow pack={pack} category={category} gear={Helper.gearWithId(allGears, packedGear.id)!} editable={editable} setting={setting} />
            )
          })
        }
        { editable &&
        <div className='hoge'>
          <GearCreateForm category={category} allGears={allGears} />
        </div>
        }
        {
          this.state.dragging &&
          <div
            onDragOver={this.onDragOverCategory}
            onDragLeave={this.onDragLeaveCategory}
            onDragEnter={this.onDragEnterToCategory}
            onDrop={this.onDropToCategory}
            className={cx('pack-category-overlay', {dragenter: this.state.dragenter})} />
        }
      </div>
    )
  }
}

interface DraggableProps {
  children: any
  index: number
  onChangeOrder: (from: number, to: number) => void
}

interface DraggableStates {
  dragging: boolean
  dragPosition: null | 'upper' | 'bottom'
}

class Draggable extends React.Component <DraggableProps, DraggableStates> {

  constructor (props: DraggableProps) {
    super(props)
    this.state = {
      dragging: false,
      dragPosition: null
    }
  }

  componentDidMount () {
    DragStore.on('drag-table-row-start', () => {
      this.setState({ dragging: true })
    })
    DragStore.on('drag-table-row-end', () => {
      this.setState({ dragging: false })
    })
  }

  componentWillUnmount () {
    DragStore.off('drag-table-row-start', () => {
      this.setState({ dragging: true })
    })
    DragStore.off('drag-table-row-end', () => {
      this.setState({ dragging: false })
      this.setState({ dragPosition: null })
    })
  }

  onDragStart = (e: any) => {
    DragAction.dragTableRowStart()
    e.dataTransfer.setData('index', this.props.index)
  }

  onDragEnd = (e: any) => {
    DragAction.dragTableRowEnd()
  }

  onDragEnter = (e: any) => {
  }

  onDragLeave = (e: any) => {
    this.setState({ dragPosition: null })
  }

  onDragOver = (e: any) => {
    const position = this.eventPosition(e)
    if (this.state.dragPosition !== position) {
      this.setState({ dragPosition: position })
    }
    e.preventDefault()
  }

  onDrop = (e: any) => {
    this.setState({ dragPosition: null })
    const from = Number(e.dataTransfer.getData('index'))
    const drop = Number(e.target.getAttribute('data-index'))
    if (from === drop) return
    const position = this.eventPosition(e)
    let to = position === 'upper' ? drop : drop + 1
    if (from < drop) to -= 1
    if (to < 0) to = 0
    this.props.onChangeOrder(from, to)
  }

  eventPosition = (e: any): 'upper' | 'bottom' => {
    const y = e.nativeEvent.offsetY
    const height = e.target.offsetHeight
    return y < height / 2 ? 'upper' : 'bottom'
  }

  render () {
    const overlayClass = cx(
      'draggable__overlay',
      this.state.dragPosition,
      { show: this.state.dragging}
    )

    return (
      <div className='draggable'
      draggable
      onDragStart={this.onDragStart}
      onDragEnd={this.onDragEnd}
      >
        {this.props.children}
        <div
        className={overlayClass}
        onDrop={this.onDrop} data-index={this.props.index}
        onDragEnter={this.onDragEnter}
        onDragLeave={this.onDragLeave}
        onDragOver={this.onDragOver}
         />
      </div>
    )
  }
}
