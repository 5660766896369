import dispatcher from '../dispatcher'

let DragAction: any = {
  /* drag from inventory */
  dragStart () {
    dispatcher.dispatch({type: 'drag-start'})
  },

  dragEnd () {
    dispatcher.dispatch({type: 'drag-end'})
  },

  /* drag of table items */
  dragTableRowStart () {
    dispatcher.dispatch({type: 'drag-table-row-start'})
  },

  dragTableRowEnd () {
    dispatcher.dispatch({type: 'drag-table-row-end'})
  }
}

export default DragAction
