import React, {Component} from 'react'
import {Redirect} from 'react-router-dom'
import firebase from 'firebase'
// actions
import PacksAction from '../actions/packs'
import SettingsAction from '../actions/settings'
// stores
import PacksStore from '../stores/packs'
import SettingsStore from '../stores/settings'
import {Link} from 'react-router-dom'
import '../scss/packs.scss'
// components
import Navbar from '../components/navbar'
import PackCreateForm from '../components/pack-create-form'
// types
import Pack from '../types/pack'
// lib
import window from '../lib/my-window'

interface Props {}

interface States {
  auth: boolean
  loadingAuth: boolean
  packs: Pack[] | null
  loadingPacks: boolean
  setting: any | null
}

export default class Packs extends Component <Props, States> {
  constructor (props: Props) {
    super(props)

    this.state = {
      auth: false,
      loadingAuth: true,
      packs: [],
      loadingPacks: false,
      setting: null
    }
  }

  componentDidMount () {
    firebase.auth().onAuthStateChanged(this.onAuthStateChanged.bind(this))
    PacksStore.on('change', this.onLoadPacks.bind(this))
    SettingsStore.on('change', this.onChangeSettings)
    window.gtagPageview({ page_path: 'packs', page_location: window.location.href })
  }

  onAuthStateChanged () {
    this.setState({
      auth: !!firebase.auth().currentUser,
      loadingAuth: false
    })

    if (!!firebase.auth().currentUser) {
      PacksAction.load()
      SettingsAction.load(firebase.auth().currentUser!.uid)
      this.setState({
        loadingPacks: true
      })
    }
  }

  onLoadPacks (store: any) {
    this.setState({
      packs: store.packs(),
      loadingPacks: false
    })
  }

  onChangeSettings = (store: any) => {
    if (!store.setting()) {
      // 初期設定を作成
      SettingsAction.init()
      return
    }
    this.setState({setting: store.setting()})
  }

  renderPacks () {
    return this.state.packs!.map((pack: Pack) => {
      return (
        <React.Fragment key={pack.id}>
            <li>
              <Link to={`/pack/${pack.id}`}>
                {pack.data.name}
              </Link>
            </li>
        </React.Fragment>
      )
    })
  }

  renderPlaceHolder () {
    return (
      <div className='packs-placeholder'>
        There are no backpacks yet.
        <br className='d-none d-sm-block' />
        Create a new backpack and add your gears.
      </div>
    )
  }

  render () {
    return <>
      {
        this.state.loadingAuth &&
        <>loading</>
      }
      {
        !this.state.auth && !this.state.loadingAuth &&
          <Redirect to='/' />
      }
      {
        this.state.auth &&
        <>
          <Navbar />
          <div className='packs'>
            <div className='container'>
              <h4>Backpacks</h4>
              <div className='packs-operation'>
                <PackCreateForm />
              </div>
              <ul className='packs-list'>
                {this.renderPacks()}
              </ul>
              {
                !this.state.loadingPacks && this.state.packs!.length === 0 &&
                  this.renderPlaceHolder()
              }
            </div>
          </div>
        </>
      }
    </>
  }
}
