import React, { Component } from 'react'
import '../scss/gear-create-form.scss'
import firebase from 'firebase'
import AmazonParser from '../lib/amazon-parser'
import * as helper from '../helpers/helper'
import cx from 'classnames'
import { Dropdown } from 'react-bootstrap'
import OutsideClickHandler from 'react-outside-click-handler'
// actions
import GearsAction from '../actions/gears'
import CategoriesAction from '../actions/categories'
// types
import Gear from '../types/gear'
import PackedGear from '../types/packed-gear'
import Category from '../types/category'


const scraperBaseUrl = 'https://us-central1-gear-dojo-scraper.cloudfunctions.net/app'
let compositting: boolean
import window from '../lib/my-window'

interface Props {
  category?: Category
  allGears?: Gear[]
}

interface States {
  focus: boolean
  saving: boolean
  keyword: string
}

export default class GearCreateForm extends Component <Props, States>{

  private inputRef = React.createRef<HTMLInputElement>()
  constructor(props: Props) {
    super(props)

    this.state = {
      focus: false,
      saving: false,
      keyword: ''
    }

  }

  componentDidMount() {
    compositting = false
  }

  componentWillReceiveProps(nextProps: any) {
    this.setState({ saving: false })
  }

  startEditing() {
    this.setState({ focus: true })
    setTimeout(() => {
      this.inputRef.current!.focus()
    })
  }

  cancelEditing = () => {
    if (!this.state.focus) return
    this.inputRef.current!.value = ''
    this.setState({ keyword: '' })
    this.setState({ focus: false })
  }

  onCompositionStart() {
    compositting = true
  }

  onCompositionEnd() {
    compositting = false
  }

  onKeyDown(e: any) {
    if (compositting) return
    if (e.key === 'Enter') {
      this.save()
    }
    if (e.key === 'Escape') {
      this.cancelEditing()
    }
  }

  onChange = (e: any) => {
    const keyword = e.target.value
    this.setState( {keyword: keyword })
  }

  async save() {
    const user = firebase.auth().currentUser
    if (!user) return
    const value = this.inputRef.current!.value
    let name = value
    let createdAt = firebase.firestore.FieldValue.serverTimestamp()
    let userId = user.uid
    let weight = 0
    let url = null

    // amazonのURLを含む場合
    if (value.indexOf('https://www.amazon') >= 0) {
      let scraperUrl = scraperBaseUrl + '/?url=' + encodeURIComponent(value)
      try {
        let product = await AmazonParser.parse(scraperUrl)
        if (!product.name) {
          alert('Sorry, we could not get product information.')
          return
        }
        name = product.name
        weight = product.weight
        url = value
      } catch (error) {
        alert(error)
        return
      }
    }

    const gear: Gear = {
      data: {
        created_at: createdAt,
        name: name,
        user_id: userId,
        weight: weight,
        tags: [],
        url: url
      }
    }
    // ギアライブラリを更新
    const gearId = await GearsAction.add(gear)
    // カテゴリに直接追加している場合はこちらも更新
    if (this.props.category && gearId) this.addToCategory(gearId, this.props.category)
    window.gtagTrackEvent('create', 'gear', gear.data.name)
    this.cancelEditing()
  }

  addToCategory = async (gearId: string, category: Category) => {
    const packedGear: PackedGear = {
      id: gearId,
      amount: 1,
      type: null
    }
    const packId = helper.packIdFromUrl(location.pathname)
    category.data.gears.push(packedGear)
    CategoriesAction.update(category, packId)
  }

  onSelectExisistingGear = (gear: Gear) => {
    this.addToCategory(gear.id!, this.props.category!)
    this.cancelEditing()
  }

  render() {
    const { allGears } = this.props
    const { keyword } = this.state

    return (
      <OutsideClickHandler onOutsideClick={this.cancelEditing}>
        <div className='gear-create-form'>
          {
            !this.state.focus && !this.state.saving &&
            <button className='btn btn-default' onClick={this.startEditing.bind(this)}>
              <i className='fas fa-plus' />
              Add new gear
              </button>
          }
          {
            !this.state.focus && this.state.saving &&
            <button className='btn btn-default'>
              <i className='fas fa-spin fa-spinner' />
              Saving
              </button>
          }
          <input
            className={cx('form-control', {shown: this.state.focus})}
            onKeyDown={this.onKeyDown.bind(this)}
            onCompositionStart={this.onCompositionStart.bind(this)}
            onCompositionEnd={this.onCompositionEnd.bind(this)}
            onChange={this.onChange}
            type='text'
            ref={this.inputRef}
            placeholder='Enter gear name' />
          {
            allGears &&
              <GearSearchResult allGears={allGears!} keyword={keyword} onSelectGear={this.onSelectExisistingGear} />
          }
        </div>
      </OutsideClickHandler>
    )
  }
}


/* Seach Result */

interface GearSearchResultProps {
  allGears: Gear[]
  keyword: string
  onSelectGear: (gear:Gear) => void
}

interface GearSearchResultStates {
  filteredGears: Gear[]
}

class GearSearchResult extends Component <GearSearchResultProps, GearSearchResultStates> {

  constructor (props: GearSearchResultProps) {
    super(props)
    this.state = {
      filteredGears: []
    }
  }

  componentWillReceiveProps (nextProps: GearSearchResultProps) {
    this.setState({ filteredGears: this.filterGears(this.props.allGears, nextProps.keyword) })
  }

  filterGears = (allGears: Gear[], keyword: string) => {
    if (!keyword) return allGears
    return allGears.filter ((gear: any) => {
      // タイトルに含まれる
      if (gear.data.name.indexOf(keyword) >= 0) return true
      // タグに含まれる
      let tagIncludesKeyword = false
      if (!gear.tags) return false
      gear.tags.forEach((tag: any) => {
        if (tag.indexOf(keyword) >= 0) tagIncludesKeyword = true
      })
      return tagIncludesKeyword
    })
  }

    render() {
      const { filteredGears } = this.state
      const { keyword, allGears } = this.props
      if (!allGears) return null
      if (!filteredGears) return null
      if (!keyword) return null
      if (filteredGears.length < 1) return null
      return(
        <Dropdown show={true}>
          <Dropdown.Menu>
            {
              filteredGears.map((gear) => <Dropdown.Item onClick={() => this.props.onSelectGear(gear)}>{gear.data.name}</Dropdown.Item>)
            }
          </Dropdown.Menu>
        </Dropdown>
      )
    }
}
