import React, { Component } from 'react'
import './components/initFirebase'
import firebase from 'firebase'
import Landing from './components/landing/landing.js'
import Packs from './components/packs'
import Pack from './components/pack'
import SharedPack from './components/shared-pack'
import Settings from './components/settings'
import Inventory from './components/inventory'
import Auth from './components/auth'
import { BrowserRouter, Route, Link, Redirect } from 'react-router-dom'
import './scss/app.scss'
import './scss/variables.scss'

class App extends Component {

  render () {
    const Ja = () => <Landing lang='ja' />
    const En = () => <Landing lang='en' />

    return(
      <div className='App'>
        <BrowserRouter>
          <Route exact path='/' component={Auth} />
          <Route path='/ja' component={Ja} />
          <Route path='/en' component={En} />
          <Route path='/packs' component={Packs} />
          <Route path='/pack/:id' component={Pack} />
          <Route path='/shared/:id' component={SharedPack} />
          <Route path='/settings' component={Settings} />
          <Route path='/inventory' component={Inventory} />
        </BrowserRouter>
      </div>
    )
  }
}

export default App;
