import scrapeIt from 'scrape-it'

const Parser = {

  parse (url) {
    return scrapeIt(url, this.selectors(url)).then((result, response) => {
      // 重さの単位を取り除く
      result.data.weight = result.data.weight.replace(/[^0-9]/g, '')
      // 商品名がなければerrorを投げる
      if (!result.data.name) throw new Error('Sorry, Could not get infomation')
      return result.data
    }).catch((error)=> {
      throw error
    })
  },

  selectors (url) {
    // amazonのドメインを元に必要なセレクタ名を算出
    if (!url) return null
    let selectors
    if (url.indexOf('amazon.com') > 0) {
      selectors = {
        name: '#productTitle',
        weight: 'bbb'
      }
    } else if (url.index.Of('amazon.co.jp') > 0) {
      selectors = {
        name: '#productTitle',
        weight: '.shipping-weight .value'
      }
    }
    return selectors
  },

  weightWithoutUnit(weight) {
    return weight.replace(/[^0-9\.]/gi, '')
  },

  switchToGrams (weight) {
    if (weight.indexOf('g') > 0 && weight.indexOf('kg') < 0) {
      return Number(this.weightWithoutUnit(weight))
    } else if (weight.indexOf('kg') > 0) {
      return this.weightWithoutUnit(weight) * 1000
    } else if (weight.indexOf('Pounds') > 0) {
      return Math.round(this.weightWithoutUnit(weight) / 2.2046 * 1000)
    } else if (weight.indexOf('onces') > 0) {
      return Math.round(this.weightWithoutUnit(weight) * 28.345)
    }
  }
}

export default Parser
