import * as React from 'react'
import cx from 'classnames'
import '../scss/side-nav.scss'
import Gear from '../types/gear'
import GearNav from './gear-nav'
import SideNavStore from '../stores/side-nav'

interface Props {
  gears: Gear[],
  setting: any
}

interface State {
  show: boolean
}

export default class SideNav extends React.Component <Props, State> {
  constructor (props: Props) {
    super (props)

    this.state = {
      show: false
    }
  }

  onClickSideNavButton = (showSideNav: boolean) => {
    this.setState({show: showSideNav})
  }

  componentDidMount () {
    SideNavStore.on('toggle', this.onClickSideNavButton)
  }

  componentWillUnmount () {
    SideNavStore.off('toggle', this.onClickSideNavButton)
  }

  render () {
    const { gears, setting } = this.props
    return (
      <div className={cx('side-nav', { show: this.state.show })}>
        <div className='side-nav-container'>
          <GearNav gears={gears} setting={setting} />
        </div>
      </div>
    )
  }
}
