import React, { Component } from 'react'
import { Dropdown } from 'react-bootstrap'
import firebase from 'firebase'
import '../scss/navbar.scss'

interface Props { }

interface States { }

export default class Navbar extends Component<Props, States> {

  constructor(props: Props) {
    super(props)
  }

  signout() {
    firebase.auth().signOut()
  }

  render() {
    const userIcon = () => {
      const user = firebase.auth().currentUser
      const src: string = user ? user.photoURL! : ''
      return <img src={src} className='user-icon' />
    }

    return (
      <nav className='navbar navbar-expand-lg fixed-top navbar-dark bg-dark'>
        <a className='navbar-brand' href='/'>GearDojo</a>
        <div className='dropdown navbar-dropdown'>
          <a className='dropdown-toggle user-menu-toggle' href='#' role='button' id='userMenu' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
            {userIcon()}
          </a>

          <div className='dropdown-menu dropdown-menu-right' aria-labelledby='userMenu'>
            <a className='dropdown-item' href='/inventory'>Inventory</a>
            <a className='dropdown-item' href='/settings'>Settings</a>
            <a className='dropdown-item' href='mailto:info@geardojo.fun'>Send feedback</a>
            <a className='dropdown-item' href='#' onClick={this.signout.bind(this)}>Sign out</a>
          </div>
        </div>
      </nav>
    )
  }
}
