import React, {Component} from 'react'
import {Modal, Button} from 'react-bootstrap'
import Gear from '../types/gear'
import Setting from '../types/setting'
import * as helper from '../helpers/helper'
import '../scss/gear-edit-modal.scss'
// actions
import GearsAction from '../actions/gears'
import CategoriesAction from '../actions/categories'

interface Props {
  gear: Gear
  show: boolean
  onHide: Function
  setting: Setting
}

export default class GearEditModal extends Component <Props> {
  private weightRef = React.createRef<HTMLInputElement>()
  private tagsRef = React.createRef<HTMLInputElement>()
  private nameRef = React.createRef<HTMLInputElement>()
  private urlRef = React.createRef<HTMLInputElement>()

  constructor (props: Props) {
    super(props)

    this.onHide = this.onHide.bind(this)
    this.tagsString = this.tagsString.bind(this)
    this.tagsArray = this.tagsArray.bind(this)
  }

  onHide () {
    this.props.onHide()
  }

  delete () {
    const packId = helper.packIdFromUrl(location.pathname)
    GearsAction.delete(this.props.gear.id!)
    CategoriesAction.deleteGear(this.props.gear.id!, packId)
  }

  completeEditing () {
    const name = this.nameRef.current!.value
    const weight = this.weightRef.current!.value
    const tags = this.tagsArray(this.tagsRef.current!.value)
    const url = this.urlRef.current!.value
    const gear: Gear = {...this.props.gear}
    gear.data.name = name
    gear.data.weight = Number(this.gramFromUnit(weight, this.props.setting.data.unit))
    gear.data.tags = tags
    gear.data.url = url
    GearsAction.update(gear)
    this.onHide()
  }

  gramFromUnit (weight: any, unit: string) {
    switch (unit) {
      case 'g':
        return weight
      case 'kg':
        return weight * 1000
      case 'lb':
        return weight * 453.592
      case 'oz':
        return weight * 28.3495
    }
  }

  tagsString (tagsArray: any) {
    if (!tagsArray) return ''
    return tagsArray.join(',')
  }

  tagsArray (tagsString: any) {
    if (!tagsString) return []
    return tagsString.split(',')
  }

  render () {
    const { gear, setting } = this.props
    return (
      <>
        <Modal className='gear-edit-modal' show={this.props.show} onHide={this.onHide.bind(this)}>
          <Modal.Body>
            <div className='form-group'>
              <label>Gear name</label>
              <input
                className='form-control'
                type='text'
                defaultValue={gear.data.name!}
                ref={this.nameRef}
                placeholder='ex：MSR ELIXIR1'
              />
            </div>
            <div className='form-group'>
              <label>Weight({setting.data.unit})</label>
              <input
                className='form-control'
                type='number'
                id='weight'
                defaultValue={helper.weightWithUnit(gear.data.weight, setting.data.unit)}
                ref={this.weightRef}
                placeholder='ex：5.5'
                />
            </div>
            <div className='form-group'>
              <label>Tags (Separate with comma)</label>
              <input
                className='form-control'
                type='text'
                id='tags'
                defaultValue={this.tagsString(gear.data.tags)}
                ref={this.tagsRef}
                placeholder='ex：shelter,tent' />
            </div>
            <div className='form-group'>
              <label>URL</label>
              <input
                className='form-control'
                type='text'
                id='url'
                defaultValue={gear.data.url!}
                ref={this.urlRef}
                placeholder='ex:https://amazon.com/~~~~' />
            </div>
            <Button variant='light' className='delete-btn' onClick={this.delete.bind(this)}>
            <i className='fa fa-trash' />
              Delete
            </Button>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="light" onClick={this.onHide.bind(this)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={this.completeEditing.bind(this)}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}
