import dispatcher from '../dispatcher'
import firebase from 'firebase'
const db = firebase.firestore()

let settingsAction = {
  async init () {
    const setting = {
      user_id: firebase.auth().currentUser.uid,
      created_at: firebase.firestore.FieldValue.serverTimestamp(),
      unit: 'g'
      // g, kg, lb, oz
    }
    try {
      await db.collection('settings').add(setting)
      dispatcher.dispatch({ type: 'change-settings', userId: setting.user_id })
    } catch (error) {
      console.log(error)
    }
  },

  async update (id, value) {
    await db.collection('settings').doc(id).update(value)
    dispatcher.dispatch({type: 'change-settings', userId: firebase.auth().currentUser.uid})
  },

  load (userId) {
    dispatcher.dispatch({type: 'change-settings', userId: userId})
  }
}

export default settingsAction
