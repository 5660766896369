import * as React from 'react'
import DragAction from '../actions/drag'
import Gear from '../types/gear'
import Setting from '../types/setting'
// components
import GearEditModal from './gear-edit-modal'
import Weight from './weight'

interface Props {
  gear: Gear,
  setting: Setting
}

interface States {
  editing: boolean
}

export default class GearLibraryItem extends React.Component <Props, States>{
  constructor (props: Props) {
    super(props)

    this.state = {
      editing: false
    }
  }

  onDragStart = (e: any) => {
    e.dataTransfer.setData('gear', JSON.stringify(this.props.gear))
    DragAction.dragStart()
  }

  onDragEnd = (e: any) => {
    DragAction.dragEnd()
  }

  startEditing () {
    this.setState({ editing: true })
  }

  cancelEditing () {
    this.setState({ editing: false })
  }

  renderTags (tags: string[]) {
    if (!tags) return []
    return tags.map((tag) =>{
      return(
        <span className='gear-library-item-tag' key={tag}>
          {`#${tag} `}
        </span>
      )
    })
  }

  render () {
    const { gear, setting } = this.props
    return (
      <>
        <li className='gear-library-item'
          draggable={true}
          onDragStart={this.onDragStart}
          onDragEnd={this.onDragEnd}
          onClick={this.startEditing.bind(this)}
        >
          <div className='gear-library-item-left'>
            <div className='gear-library-item-name'>
              {gear.data.name}
            </div>
            <div className='gear-library-item-tags'>
              {this.renderTags(gear.data.tags)}
            </div>
          </div>
          <div className='gear-library-item-right'>
            <Weight weight={gear.data.weight} unit={setting.data.unit} />
          </div>
        </li>
        <GearEditModal
          show={this.state.editing}
          onHide={this.cancelEditing.bind(this)}
          gear={gear}
          setting={setting}
        />
      </>
    )
  }
}
