import React, { Component, Fragment } from 'react'
import firebase from 'firebase'
import CategoriesAction from '../actions/categories'
import Category from '../types/category'
import '../scss/category-create-form.scss'
import window from '../lib/my-window'
import Pack from '../types/pack'
let compositting: boolean

interface Props {
  pack: Pack
}

interface States {
  focus: boolean
}

export default class CategoryCreateForm extends Component <Props, States> {

  private inputRef = React.createRef<HTMLInputElement>()

  constructor(props: Props) {
    super(props)

    this.state = {
      focus: false
    }
  }

  componentDidMount () {
    compositting = false
  }

  startEditing () {
    this.setState({ focus: true })
    setTimeout(() => {
      this.inputRef.current!.focus()
    }, 50)
  }

  onCompositionStart () {
    compositting = true
  }

  onCompositionEnd () {
    compositting = false
  }

  onKeyDown (e: any) {
    if (compositting) return
    if (e.key === 'Enter') {
      this.completeEditing()
    }
    if (e.key === 'Escape') {
      this.cancelEditing()
    }
  }

  cancelEditing () {
    this.setState({ focus: false })
  }

  completeEditing() {
    const category: Category = {
      id: '',
      data: {
        name: this.inputRef.current!.value,
        created_at: firebase.firestore.FieldValue.serverTimestamp(),
        pack_id: this.props.pack.id,
        gears: []
      }
    }

    CategoriesAction.create(category)
    this.cancelEditing()
    window.gtagTrackEvent('create', 'category', name)
  }

  render() {
    return (
      <div className='category-create-form'>
        {
          !this.state.focus &&
            <button className='btn btn-default' onClick={this.startEditing.bind(this)}>
              <i className='fas fa-plus' />
              Create new category
            </button>
        }
        {
          this.state.focus &&
            <>
              <input
                type='text'
                className='form-control'
                ref={this.inputRef}
                placeholder='Category name（ex：Cooker, Shelter）'
                onCompositionStart={this.onCompositionStart.bind(this)}
                onCompositionEnd={this.onCompositionEnd.bind(this)}
                onKeyDown={this.onKeyDown.bind(this)}
                onBlur={this.cancelEditing.bind(this)}/>
            </>
        }
      </div>
    )
  }
}
