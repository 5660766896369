import React, {Component} from 'react'
import {Modal, Button} from 'react-bootstrap'
import PackAction from '../actions/pack'
import Pack from '../types/pack'
import '../scss/pack-edit-modal.scss'

interface Props {
  pack: Pack
  show: boolean
  onHide: Function
}

export default class PackEditModal extends Component <Props> {
  private nameRef = React.createRef<HTMLInputElement>()

  constructor (props: Props) {
    super(props)

    this.onHide = this.onHide.bind(this)
  }

  onHide () {
    this.props.onHide()
  }

  completeEditing () {
    const name = this.nameRef.current!.value
    PackAction.update(this.props.pack.id, name)
    this.onHide()
  }

  render () {
    const pack = this.props.pack
    return (
      <>
        <Modal className='pack-edit-modal' show={this.props.show} onHide={this.onHide.bind(this)}>
          <Modal.Body>
            <div className='form-group'>
              <label>Backpack name</label>
              <input
                className='form-control'
                type='text'
                defaultValue={pack.data.name}
                ref={this.nameRef}
                placeholder='ex：Mt.AAA hiking 2019'
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="light" onClick={this.onHide.bind(this)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={this.completeEditing.bind(this)}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}
