import * as React from 'react';

interface Props {
  weight: number, // provided by gram
  unit: string
}

export default class Weight extends React.Component <Props> {

  constructor (props: Props) {
    super(props)
  }

  weightWithUnit = (weight: number, unit: string) => {
    switch (unit) {
      case 'g':
        return weight
      case 'kg':
        return Math.round(weight / 1000 * 100) / 100
      case 'lb':
        return Math.round(weight / 453.592 * 100) / 100
      case 'oz':
        return Math.round(weight / 28.3495 * 100) / 100
    }
  }

  render () {
    const { weight, unit } = this.props
    return (
      <>
        {this.weightWithUnit(weight, unit) + ' ' +  unit }
      </>
    )
  }
}
