import React, {Component} from 'react'
import {Redirect} from 'react-router-dom'
import firebase from 'firebase'
import * as helper from '../helpers/helper'
import '../scss/pack.scss'
// actions
import PackAction from '../actions/pack'
import CategoriesAction from '../actions/categories'
import GearsAction from '../actions/gears'
import SettingsAction from '../actions/settings'
// stores
import PackStore, { _PackStore as PackStoreClass }  from '../stores/pack'
import CategoriesStore, { _CategoriesStore as CategoriesStoreClass } from '../stores/categories'
import GearsStore, { _GearsStore as GearsStoreClass } from '../stores/gears'
import SettingsStore, {_SettingsStore as SettingsStoreClass } from '../stores/settings'
// components
import Navbar from './navbar'
import SideNav from './side-nav'
import PackHeader from './pack-header'
import PackTable from './pack-table'
import CategoryCreateForm from './category-create-form'
// types
import Category from '../types/category'
import PackType from '../types/pack'
import Gear from '../types/gear'

interface Props {
  match: any
}

interface State {
  auth: boolean,
  loadingAuth: boolean,
  setting: any,
  gears: Gear[],
  pack: PackType | null,
  categories: Category[],
}

interface CustomWindow extends Window {
    gtagPageview: any
}
declare var window: CustomWindow;

export default class Pack extends Component <Props, State, CustomWindow> {
  constructor (props: Props) {
    super(props)

    this.state = {
      auth: !firebase.auth().currentUser,
      gears: [],
      pack: null,
      categories: [],
      loadingAuth: true,
      setting: null
    }
  }

  componentDidMount () {
    firebase.auth().onAuthStateChanged(this.onAuthStateChanged.bind(this))
    PackStore.on('change', this.onChangePack.bind(this))
    CategoriesStore.on('change', this.onChangeCategories.bind(this))
    GearsStore.on('change', this.onChangeGears.bind(this))
    SettingsStore.on('change', this.onChangeSettings)
    window.gtagPageview({ page_path: 'pack', page_location: window.location.href })
  }

  onAuthStateChanged () {
    const packId = this.props.match.params.id
    const user = firebase.auth().currentUser
    this.setState({
      auth: !!user,
      loadingAuth: false
    })
    if (user) {
      PackAction.load(packId)
      GearsAction.load()
      SettingsAction.load(user.uid)
    }
  }

  onChangePack (store: PackStoreClass) {
    this.setState(
      {
        pack: store.pack()
      })
    if (this.state.pack) {
      CategoriesAction.load(this.state.pack.id)
    }
  }

  onChangeCategories (store: CategoriesStoreClass) {
    this.setState(
      {
        categories: store.categories()
      }
    )
  }

  onChangeGears (store: GearsStoreClass) {
    this.setState({gears: store.gears()})
  }

  onChangeSettings = (store: SettingsStoreClass) => {
    if (!store.setting()) {
      // 初期設定を作成
      SettingsAction.init()
      return
    }
    this.setState({setting: store.setting()})
  }

  render () {
    const { pack, categories, gears, setting } = this.state
    return (
       <div className='pack-wrapper'>
        {
          this.state.loadingAuth &&
          <>loading auth</>
        }
        {
          !this.state.auth && !this.state.loadingAuth &&
          <Redirect to='/' />
        }
        {
          pack && setting &&
            <>
              <Navbar />
              <SideNav gears={this.state.gears} setting={this.state.setting} />
              <div className='pack-container editable'>
                <div className='pack-container-col'>
                  <PackHeader pack={pack} setting={setting} editable categories={categories} allGears={this.state.gears} />
                  <CategoryCreateForm pack={pack} />
                  <PackTable pack={pack} categories={categories} setting={setting} editable allGears={gears} />
                </div>
              </div>
            </>
        }
      </div>
    )
  }
}
