import dispatcher from '../dispatcher'
import firebase from 'firebase'
import Category from '../types/category'
import PackedGear from '../types/packed-gear'
const db = firebase.firestore()
const dispatchType = 'change-categories'


let CategoriesAction = {
  async create (category: Category) {
    await db.collection('categories').add(category.data)
    dispatcher.dispatch({type: dispatchType, item: {packId: category.data.pack_id}})
  },

  async delete (categoryId: string, packId: string) {
    await db.collection('categories').doc(categoryId).delete()
    dispatcher.dispatch({type: dispatchType, item: {packId: packId}})
  },

  async deleteGear (gearId: string, packId: string) {
    const gear: PackedGear = {
      id :gearId,
      type: null,
      amount: 1
    }
    const response = await db.collection('categories').where('gears', 'array-contains-any', [gear]).get()
    const promises = response.docs.map ((doc) => {
      const category = {
        id: doc.id,
        data: doc.data()
      }
      category.data.gears.forEach ((gear: any, index: number) => {
        if (gear.id === gearId) {
          category.data.gears.splice(index, 1)
        }
      })
      return db.collection('categories').doc(category.id).update(category.data)
    })
    await Promise.all(promises)
  },

  async update (category: Category, packId: string) {
    await db.collection('categories').doc(category.id).update(category.data)
    dispatcher.dispatch({ type: dispatchType, item: {packId: packId}})
  },

  async updateName (categoryId: string, newName: string, packId: string) {
    await db.collection('categories').doc(categoryId).update({
      name: newName
    })
    dispatcher.dispatch({ type: dispatchType, item: {packId: packId}})
  },

  async duplicate(categories: Category[], packId: string) {
    const promises = categories.map((category) => {
      const newCategory = {
        id: '',
        data: {
          name: category.data.name,
          created_at: firebase.firestore.FieldValue.serverTimestamp(),
          pack_id: packId,
          gears: category.data.gears
        }
      }
      return db.collection('categories').add(newCategory.data)
    })

    const docRefs = await Promise.all(promises)
    const categoryIds = docRefs.map(docRef => docRef.id)
    dispatcher.dispatch({ type: 'duplicate-categories', item: { ids: categoryIds } })
  },

  load (packId: string) {
    if (!packId) return
    setTimeout(() => {
      dispatcher.dispatch({type: dispatchType, item: {packId: packId}})
    }, 100)
  }
}

export default CategoriesAction
