import React, {Component} from 'react'
import CategoriesAction from '../../actions/categories'
import Category from '../../types/category'
import Pack from '../../types/pack'
import Gear from '../../types/gear'
import Setting from '../../types/setting'
import { Modal, Button } from 'react-bootstrap'
import cx from 'classnames'
import '../../scss/category-header.scss'
import Weight from '../weight'
import  * as helper from '../../helpers/helper'

interface Props {
  allGears: Gear[]
  pack: Pack
  category: Category
  setting :Setting
  editable: boolean
}

export default class CategoryHeader extends Component <Props> {

  render () {
    const { pack, category, editable, setting, allGears } = this.props
    const packedGears = category.data.gears

    return (
      <div className={cx('category-header', {'no-gears': packedGears.length === 0 })}>
        <div className={cx('category-header-row', {shared: !editable})}>
          <div className='category-header-name'>
            { category.data.name }
          </div>
          <div className='category-header-weight'>
            <Weight weight={helper.totalWeightInCategory(allGears, category)} unit={setting.data.unit} />
          </div>
          {
            editable &&
              <>
                <CategoryEditButton
                className='category-header-btn'
                category={category}
                pack={pack}
                >
                  <i className='fa fa-edit' />
                </CategoryEditButton>
                <CategoryDeleteButton
                  className='category-header-btn'
                  category={category}
                  pack={pack}
                  >
                  <i className='fa fa-trash' />
                </CategoryDeleteButton>
              </>
          }
        </div>
        {
          packedGears.length === 0 && editable &&
          <div className='category-header-placeholder'>
            This category is empty.
          </div>
        }
      </div>
    )
  }
}


const CategoryDeleteButton = (props: {category: Category, pack: Pack, className: string, children: any}) => {
  const { category, pack, className, children } = props
  const deleteCategory = async () => {
    try {
      await CategoriesAction.delete(category.id!, pack.id)
      alert('Deleted')
    } catch (e) {
      alert (e)
    }
  }

  return (
    <button className={`btn ${className}`} onClick={deleteCategory}>
      {children}
    </button>
  )
}

interface EditModalProps {
  pack: Pack
  category: any
  show: boolean
  onHide: () => void
}

class CategoryEditModal extends Component <EditModalProps> {

  private nameRef = React.createRef<any>()

  constructor (props: EditModalProps) {
    super(props)
    this.nameRef = React.createRef()
  }

  async save () {
    const name = this.nameRef.current.value
    try {
      await CategoriesAction.updateName(this.props.category.id, name, this.props.pack.id)
    } catch (e) {
      alert(e)
    }
    this.props.onHide()
  }

  render () {
    const { pack, category, show, onHide }  = this.props
    return (
      <>
        <Modal className='category-edit-modal' show={show} onHide={onHide}>
          <Modal.Body>
            <div className='form-group'>
              <label>カテゴリーの名前</label>
              <input
                className='form-control'
                type='text'
                defaultValue={category.name}
                ref={this.nameRef}
                placeholder='例：クッカー'
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="light" onClick={onHide}>
              Cancel
            </Button>
            <Button variant="primary" onClick={this.save.bind(this)}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

interface CategoryEditButtonProps {
  category: Category
  pack: Pack
  className: string
}

interface CategoryEditButtonStates {
  showModal: boolean
}

class CategoryEditButton extends Component <CategoryEditButtonProps, CategoryEditButtonStates> {
  constructor (props: CategoryEditButtonProps) {
    super(props)
    this.state = {
      showModal: false
    }
  }

  showModal () {
    this.setState({ showModal: true })
  }

  hideModal () {
    this.setState({ showModal: false })
  }

  render () {
    const { category, pack, className } = this.props
    return (
      <>
        <button className={`btn ${className}`} onClick={this.showModal.bind(this)}>
          {this.props.children}
        </button>
        <CategoryEditModal
          show={this.state.showModal}
          onHide={this.hideModal.bind(this)}
          pack={pack}
          category={category} />
      </>
    )
  }
}
