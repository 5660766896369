import dispatcher from '../dispatcher'
import {EventEmitter2 as EventEmitter} from 'eventemitter2'
import firebase from 'firebase'
const db = firebase.firestore()

export class _SettingsStore extends EventEmitter {
  constructor () {
    super()
    this._settings = null
  }

  setting () {
    return this._settings
  }

  initSettings (doc) {
    return {
      id: doc.id,
      data: doc.data()
    }
  }

  async load (userId) {
    if (!userId) return
    const data = await db.collection('settings').where('user_id', '==', userId).get()
    if (data.docs.length === 0) {
      return
    }
    this._settings = this.initSettings(data.docs[0])
  }
}

let SettingsStore = new _SettingsStore()
SettingsStore.dispatchToken = dispatcher.register(async (payload) => {
  if (payload.type === 'change-settings') {
    await SettingsStore.load(payload.userId)
    SettingsStore.emit('change', SettingsStore)
  }
})

export default SettingsStore
