import dispatcher from '../dispatcher'
import { EventEmitter2 as EventEmitter } from 'eventemitter2'

export class _SideNavStore extends EventEmitter {
  public showSideNav: boolean = false
  public dispatchToken: string | undefined
  constructor () {
    super()
  }
}

let SideNavStore = new _SideNavStore()
SideNavStore.dispatchToken = dispatcher.register((payload: any)=> {
  if ( payload.type === 'toggle') {
    SideNavStore.showSideNav = !SideNavStore.showSideNav
    SideNavStore.emit('toggle', SideNavStore.showSideNav)
  }
})

export default SideNavStore
