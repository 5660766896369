import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'

export default class Auth extends Component {

  render () {
    let lang = window.navigator.language
    return (
      <div className='auth'>
        {
          lang === 'ja'
            ? <Redirect to='/ja' />
            : <Redirect to='/en' />
        }
      </div>
    )
  }
}
