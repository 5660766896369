import React, {Component} from 'react'
import '../scss/pack-header.scss'
import firebase from 'firebase'
import PackEditModal from './pack-edit-modal'
import PackDeleteButton from './pack-delete-button'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import Weight from './weight'
import PackSelectButton from './pack-select-button'
import SideNavToggleButton from './side-nav-toggle-button'
// types
import Gear from '../types/gear'
import Pack from '../types/pack'
import Category from '../types/category'
import Setting from '../types/setting'
// stores
import PackStore from '../stores/pack'
import CategoriesStore from '../stores/categories'
import GearsStore from '../stores/gears'
// actions
import PackAction from '../actions/pack'
import GearsAction from '../actions/gears'
import CategoriesAction from '../actions/categories'
import * as helper from '../helpers/helper'
import window from '../lib/my-window'

interface Props {
  allGears: Gear[]
  pack: Pack
  categories: Category[]
  editable?: boolean
  setting?: any
}

interface States {
  showEditModal: boolean
}

export default class PackHeader extends Component <Props, States>{

  constructor (props: Props) {
    super(props)

    this.state = {
      showEditModal: false
    }
  }

  render () {
    const { pack, editable, setting, allGears, categories } = this.props

    return (
      <div className='pack-header'>
        <div className='pack-header-info'>
          <div className='pack-header-title'>
            <h2>
              {pack.data.name}
            </h2>
            { editable && <PackSelectButton selectedPack={pack} /> }
          </div>
          <WeightBlock setting={setting} pack={pack} allGears={allGears} categories={categories} />
        </div>
        {
          editable &&
            <div className='pack-header-operation'>
              <SideNavToggleButton className='btn pack-header-btn d-none d-sm-inline'>
                <i className='fas fa-list' />
              </SideNavToggleButton>
              <PackShareButton className='btn pack-header-btn' pack={pack}>
                <i className='fa fa-share' />
              </PackShareButton>
              <PackEditButton className='btn pack-header-btn' pack={pack}>
                <i className='fa fa-edit' />
              </PackEditButton>
              <PackDuplicateButton className='btn pack-header-btn' pack={pack} categories={categories}>
                <i className='fa fa-copy' />
              </PackDuplicateButton>
              <PackDeleteButton className='btn pack-header-btn' pack={pack}>
                <i className='fa fa-trash' />
              </PackDeleteButton>
            </div>
        }
      </div>
    )
  }
}

interface WeightBlockProps {
  allGears: Gear[]
  pack: Pack
  categories: Category[]
  setting: Setting
}

class WeightBlock extends React.Component <WeightBlockProps> {
  render () {
    const { pack, setting, allGears, categories } = this.props
    return (
      <div className='weight-block'>
        <div className='weight-block-titles'>
          <div className='weight-block-title'>
            Base
          </div>
          <div className='weight-block-title'>
            Consumable
          </div>
          <div className='weight-block-divider weight-block-divider-left' />
          <div className='weight-block-title weight-block-title-sum'>
            Pack
          </div>
          <div className='weight-block-title'>
            Worn
          </div>
          <div className='weight-block-divider weight-block-divider-left' />
          <div className='weight-block-title weight-block-title-sum'>
            Total
          </div>
        </div>

        <div className='weight-block-values'>
          <div className='weight-block-value'>
            <Weight weight={helper.baseWeightInCategories(allGears, categories)} unit={setting.data.unit} />
          </div>
          <div className='weight-block-value'>
            <Weight weight={helper.consumableWeightInCategories(allGears, categories)} unit={setting.data.unit} />
          </div>
          <div className='weight-block-divider weight-block-divider-right' />
          <div className='weight-block-value weight-block-value-sum'>
            <Weight weight={helper.packWeightInCategories(allGears, categories)} unit={setting.data.unit} />
          </div>
          <div className='weight-block-value'>
            <Weight weight={helper.wornWeightInCategories(allGears, categories)} unit={setting.data.unit} />
          </div>
          <div className='weight-block-divider weight-block-divider-right' />
          <div className='weight-block-value weight-block-value-sum'>
            <Weight weight={helper.totalWeightInCategories(allGears, categories)} unit={setting.data.unit} />
          </div>
        </div>
      </div>
    )
  }
}


interface EditButtonProps {
  pack: Pack
  className: string
}

interface EditButtonStates {
  showModal: boolean
}

class PackEditButton extends Component <EditButtonProps, EditButtonStates>{

  private target = React.createRef<any>()
  constructor (props: EditButtonProps) {
    super(props)
    this.state = {
      showModal: false
    }
    this.target=React.createRef()
  }

  onClick () {
    this.setState({ showModal: true })
  }

  onHideModal () {
    this.setState({ showModal: false })
  }

  render () {
    return (
      <>
        <OverlayTrigger
          placement='bottom'
          overlay={
            <Tooltip id='test'>
              Edit backpack name
            </Tooltip>
          }
        >
          <a
            onClick={this.onClick.bind(this)} className={this.props.className}
            ref={this.target}
            >
            {this.props.children}
          </a>
        </OverlayTrigger>
        <PackEditModal
          show={this.state.showModal}
          onHide={this.onHideModal.bind(this)}
          pack={this.props.pack} />
      </>
    )
  }
}

interface ShareButtonProps {
  pack: Pack
  className: string
  children: any
}

const PackShareButton = (props: ShareButtonProps) => {
  const onClick = () => {
    const url = `${window.location.host}/shared/${props.pack.id}`
    copyText(url)
    alert('Link copied to clipboard.')
    window.gtagTrackEvent('copy_link', 'share', url)
  }

  const copyText = (url: string) => {
    const textarea = document.createElement('textarea')
    textarea.value = url
    document.body.appendChild(textarea)
    textarea.select()
    document.execCommand('copy')
    document.body.removeChild(textarea)
  }

  return (
    <OverlayTrigger
      placement='bottom'
      overlay={
        <Tooltip id='hoge'>
          Copy link
        </Tooltip>
      }
      >
      <a onClick={onClick} className={props.className}>
        {props.children}
      </a>
    </OverlayTrigger>
  )
}


interface DuplicateButtonProps {
  children: any
  className?: string
  pack: Pack
  categories: Category[]
}

class PackDuplicateButton extends Component<DuplicateButtonProps> {
  private newPackId: string | null = null

  constructor (props: DuplicateButtonProps) {
    super(props)
  }

  componentDidMount () {
    PackStore.on('duplicate', this.duplicateCategories)
    CategoriesStore.on('duplicate', this.onUpdateCategories)
  }

  componentWillUnmount () {
    PackStore.off('duplicate', this.duplicateCategories)
    CategoriesStore.off('duplicate', this.onUpdateCategories)
  }

  duplicatePack = () => {
    // packを作成
    PackAction.duplicate(this.props.pack)
  }

  duplicateCategories = (newPackId: string) => {
    this.newPackId = newPackId
    CategoriesAction.duplicate(this.props.categories, newPackId)
  }

  onUpdateCategories = () => {
    window.location.href = `/pack/${this.newPackId}`
  }

  render () {
    const { className, children } = this.props

    return (
      <OverlayTrigger
      placement='bottom'
      overlay={
        <Tooltip id='hoge'>
          Duplicate pack
        </Tooltip>
      }>
        <a onClick={this.duplicatePack} className={className}>
          {children}
        </a>
      </OverlayTrigger>
    )
  }
}
