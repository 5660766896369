import React, {Component} from 'react'
import '../scss/pack-create-form.scss'
import PacksAction from '../actions/packs'
import firebase from 'firebase'
import window from '../lib/my-window'
let compositting: boolean

interface Props {}

interface States {
  focus: boolean
}

export default class PackCreateForm extends Component <Props, States> {
  private inputRef = React.createRef<HTMLInputElement>()

  constructor (props: Props) {
    super(props)

    this.state = {
      focus: false
    }

    this.inputRef = React.createRef()
    this.startEditing = this.startEditing.bind(this)
    this.cancelEditing = this.cancelEditing.bind(this)
  }

  startEditing () {
    this.setState({ focus: true })
    setTimeout(() => {
      this.inputRef.current!.focus()
    })
  }

  cancelEditing () {
    this.setState({ focus: false })
  }

  onKeyDown (e: React.KeyboardEvent<HTMLInputElement>) {
    if (compositting) return
    if (e.key === 'Enter') {
      this.save()
    }
    if (e.key === 'Escape') {
      this.cancelEditing()
    }
  }

  save () {
    const user = firebase.auth().currentUser
    if (!user) return
    const pack = {
      id: '',
      data: {
        name: this.inputRef.current!.value,
        created_at: firebase.firestore.FieldValue.serverTimestamp(),
        user_id: user.uid
      }
    }
    PacksAction.create(pack)
    this.inputRef.current!.value = ''
    this.cancelEditing()
    window.gtagTrackEvent('create', 'pack', pack.data.name)
  }

  onCompositionStart () {
    compositting = true
  }

  onCompositionEnd () {
    compositting = false
  }

  render () {
    return (
      <div className='pack-create-form'>
        {
          !this.state.focus &&
            <button className='btn btn-default btn-create' onClick={this.startEditing}>
              <i className='fa fa-plus' />
              Create new backpack
            </button>
        }
        {
          this.state.focus &&
            <React.Fragment>
              <input
                className='form-control'
                type='text'
                ref={this.inputRef}
                onBlur={this.cancelEditing}
                onKeyDown={this.onKeyDown.bind(this)}
                onCompositionStart={this.onCompositionStart.bind(this)}
                onCompositionEnd={this.onCompositionEnd.bind(this)}
                placeholder='Enter backpack name（ex：Mt.AAA hiking 2019）'
                />
            </React.Fragment>
        }

      </div>
    )
  }
}
