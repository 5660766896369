import React from 'react'
import SettingsAction from '../../actions/settings'
import Setting from  '../../types/setting'

interface Props {
  setting: Setting
}

interface States {
  unit: 'g' | 'kg' | 'lb' | 'oz'
}

export default class UnitSetting extends React.Component<Props, States> {

  constructor (props: Props) {
    super(props)
    this.state = {
      unit: props.setting.data.unit
    }
  }

  componentWillReceiveProps (nextProps: Props) {
    this.setState({ unit: nextProps.setting.data.unit })
  }

  onChangeUnit = (e: any) => {
    const value = {
      unit: e.target.value
    }
    SettingsAction.update(this.props.setting.id, value)
  }

  render () {
    return (
      <div className='form-group'>
        <label>Unit</label>
        <select className='custom-select' onChange={this.onChangeUnit} value={this.state.unit}>
          <option value='g'>g</option>
          <option value='kg'>kg</option>
          <option value='lb'>lb</option>
          <option value='oz'>oz</option>
        </select>
      </div>
    )
  }
}
