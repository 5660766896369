import React, {Component} from 'react'
import cx from 'classnames'
import '../../scss/table-row.scss'
import Weight from '../weight'
import { Dropdown } from 'react-bootstrap'
import * as Helper from '../../helpers/helper'
import GearEditModal from '../gear-edit-modal'
// actions
import GearsAction from '../../actions/gears'
import CategoriesAction from '../../actions/categories'
// types
import Category from '../../types/category'
import Pack from '../../types/pack'
import Gear from '../../types/gear'
import Setting from '../../types/setting'

interface Props {
  gear: Gear
  category: Category
  pack: Pack
  editable: boolean
  setting: Setting
}

interface States {
  editingGear: boolean
}

export default class TableRow extends Component <Props, States> {

  constructor (props: Props) {
    super(props)
    this.state = {
      editingGear: false
    }
  }

  onClickGearName = () => {
    if (this.props.editable) this.setState({ editingGear: true })
  }

  onCancelEditingGear = () => {
    this.setState({ editingGear: false })
  }

  render () {
    const {gear, category, pack, editable, setting} = this.props
    if (!gear || !category) return null
    const packedGear = Helper.packedGearWithId(gear.id!, category)
    const amount = packedGear!.amount
    const type = packedGear!.type

    return (
      <div className='table-row' draggable={false}>
        <a onClick={this.onClickGearName} className={cx('table-row-name', {'editable': editable})}>
          {
            gear.data.url && !editable
              ? <a href={gear.data.url}>{gear.data.name}</a>
              : <span>{gear.data.name}</span>
          }
        </a>
        <div className='table-row-amount'>
          {
            editable
              ?<AmountEditForm {...this.props} />
              :<>{amount}</>
          }
        </div>
        <div className='table-row-weight'>
          <Weight weight={gear.data.weight * amount} unit={setting.data.unit} />
        </div>
        <div className='table-row-type'>
          <TypeSelectButton editable={editable} className='table-row-btn' gear={gear} packId={pack.id} category={category} />
        </div>
        {
          editable &&
            <>
              <GearRemoveButton className='table-row-btn' packId={pack.id} category={category} gear={gear}>
                <i className='fa fa-cross' />
              </GearRemoveButton>
            </>
        }
        <GearEditModal
          show={this.state.editingGear}
          onHide={this.onCancelEditingGear}
          gear={gear}
          setting={setting}
        />
       </div>
    )
  }
}

interface AmountEditFormProps {
  gear: Gear
  category: Category
  pack: Pack
}

interface AmountEditFormStates {
  editing: boolean
}

class AmountEditForm extends Component <AmountEditFormProps, AmountEditFormStates> {

  private inputRef = React.createRef<HTMLInputElement>()
  constructor (props: AmountEditFormProps) {
    super(props)

    this.state = {
      editing: false
    }
  }

  onClick () {
    if (this.state.editing) return
    this.setState({ editing: true })
    setTimeout(() => {
      this.inputRef.current!.focus()
    }, 50)
  }

  async onBlur () {
    const amount = Number(this.inputRef.current!.value)
    await this.save(amount)
    this.setState({ editing: false })
  }

  async save (amount: number) {
    const { gear, category, pack } = this.props
    category.data.gears.forEach ((packedGear) => {
      if (gear.id === packedGear.id) {
        packedGear.amount = amount
      }
    })
    CategoriesAction.update(category, pack.id)
  }

  render () {
    const { gear, category } = this.props
    if (!gear || !category) return null
    const packedGear = Helper.packedGearWithId(gear.id!, category)

    return (
      <div
        className={cx('amount-edit-form', { ediging: this.state.editing })} onClick={this.onClick.bind(this)}
        >
        {
          this.state.editing
          ? <input
            type='number'
            className='form-control form-control-sm'
            defaultValue={packedGear!.amount}
            ref={this.inputRef}
            onBlur={this.onBlur.bind(this)}
             />
          : <>{packedGear!.amount}</>
        }
      </div>
    )
  }
}

interface GearRemoveButtonProps {
  className: string
  category: Category
  gear: Gear
  packId: string
}

class GearRemoveButton extends Component  <GearRemoveButtonProps>{

  remove () {
    const { gear, category, packId } = this.props
    category.data.gears.forEach((packedGear, index) => {
      if (packedGear.id === gear.id) {
        category.data.gears.splice(index, 1)
      }
    })
    CategoriesAction.update(category, packId)
  }

  render () {
    return (
      <button
        className={`btn gear-remove-button ${this.props.className}`}
        onClick={this.remove.bind(this)}
        >
        <i className='fa fa-times' />
      </button>
    )
  }
}


interface TypeSelectToggleProps {
  onClick: Function
  className: string
}

class TypeSelectToggle extends React.Component <TypeSelectToggleProps> {

  onClick = (e: any) => {
    e.preventDefault()
    this.props.onClick(e)
  }

  render () {
    return (
      <button className={`btn type-select-btn ${this.props.className}`} onClick={this.onClick}>
        {this.props.children}
      </button>
    )
  }
}


interface TypeSelectButtonProps {
  editable: boolean
  gear: Gear
  packId: string
  category: Category
  className: string
}

class TypeSelectButton extends React.Component <TypeSelectButtonProps> {

  constructor (props: TypeSelectButtonProps) {
    super(props)
  }

  renderIcon = (type: null | 'consumable' | 'worn') => {
    switch (type) {
      case null:
        if (!this.props.editable) return null
        return <img className='type-select-icon' src='https://i.gyazo.com/68afc9ffdb31f50303252ee6e498680c.png' />
        return
      case 'consumable':
        return <img className='type-select-icon' src='https://i.gyazo.com/e0e6781d097ccdb5a8cdf7625d590a63.png' />
      case 'worn':
        return <img className='type-select-icon' src='https://i.gyazo.com/1be154772725db126fa691682987530f.png' />
    }
  }

  onSelect = async (eventKey: any, event: any) => {
    await this.save(eventKey)
  }

  async save (type: any) {
    const { gear, category, packId } = this.props
    const packedGear = Helper.packedGearWithId(gear.id!, category)
    category.data.gears.forEach((packedGear) => {
      if (packedGear.id === gear.id) {
        packedGear.type = type
      }
    })
    CategoriesAction.update(category, packId)
  }

  render () {
    const { className, gear, editable, category} = this.props
    const packedGear = Helper.packedGearWithId(gear.id!, category)
    const type = packedGear!.type

    if (editable) return (
      <Dropdown onSelect={this.onSelect}>
        <Dropdown.Toggle id='hoge' as={TypeSelectToggle} className={className}>
          {this.renderIcon(type || null)}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item eventKey={null}>
            <img className='type-select-icon' src='https://i.gyazo.com/050a50e30184cf44937603ee78fa7b1f.png' />
            Basics
          </Dropdown.Item>
          <Dropdown.Item eventKey='worn'>
            <img className='type-select-icon' src='https://i.gyazo.com/1be154772725db126fa691682987530f.png' />
            Worn
          </Dropdown.Item>
          <Dropdown.Item eventKey='consumable'>
            <img className='type-select-icon' src='https://i.gyazo.com/e0e6781d097ccdb5a8cdf7625d590a63.png' />
            Consumable
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    )

    // in the case of shared pack
    return (
      <div className='table-row-btn disabled'>
        {this.renderIcon(type || null)}
      </div>
    )
  }
}
