import React, {Component} from 'react'
import {Redirect} from 'react-router-dom'
import * as helper from '../helpers/helper'
// types
import Category from '../types/category'
import Gear from '../types/gear'
import Pack from '../types/pack'
// actions
import PackAction from '../actions/pack'
import CategoriesAction from '../actions/categories'
import GearsAction from '../actions/gears'
import SettingsAction from '../actions/settings'
// stores
import PackStore from '../stores/pack'
import CategoriesStore from '../stores/categories'
import GearsStore from '../stores/gears'
import SettingsStore from '../stores/settings'
// components
import PackHeader from './pack-header'
import PackTable from './pack-table'
import CategoryCreateForm from './category-create-form'
import PackFooter from './pack-footer'
import '../scss/shared-pack.scss'
// lib
import window from '../lib/my-window'


interface Props {
  match: any
}

interface States {
  pack: Pack | null
  categories: Category[]
  gears: Gear[]
  setting: any
}

export default class SharedPack extends Component <Props, States> {
  constructor (props: Props) {
    super(props)

    this.state = {
      pack: null,
      categories: [],
      gears: [],
      setting: null
    }
  }

  componentDidMount () {
    window.gtagPageview({ page_path: 'shared', page_location: window.location.href })
    PackStore.on('change', this.onChangePack.bind(this))
    CategoriesStore.on('change', this.onChangeCategories.bind(this))
    GearsStore.on('change', this.onChangeGears.bind(this))
    SettingsStore.on('change', this.onChangeSetting)
    PackAction.load(this.props.match.params.id)
  }

  onChangePack (store: any) {
    this.setState(
      {
        pack: store.pack()
      })
    if (this.state.pack) {
      CategoriesAction.load(this.state.pack.id)
      SettingsAction.load(this.state.pack.data.user_id)
    }
  }

  onChangeCategories (store: any) {
    this.setState(
      {
        categories: store.categories()
      }
    )
    GearsAction.loadWithUserId(this.state.pack!.data.user_id)
  }

  onChangeGears (store: any) {
    this.setState({gears: store.gears()})
  }

  onChangeSetting = (store: any) => {
    this.setState({ setting: store.setting() })
  }

  render () {
    const { pack, gears, categories, setting } = this.state

    return (
      <>
        {
          pack && setting &&
          <>
            <div className='pack-container'>
              <div className='pack-container-col'>
                <PackHeader pack={pack} setting={this.state.setting} allGears={this.state.gears} categories={categories}　editable={false} />
                <PackTable pack={pack} allGears={this.state.gears} categories={this.state.categories} setting={this.state.setting} editable={false}　/>
                <PackFooter pack={pack} />
              </div>
            </div>
            <div className='shared-pack-footer'>
              <span>Created by &nbsp;</span>
              <a className='logotype' href='/'>
                GearDojo
              </a>
            </div>
          </>
        }
      </>
    )
  }
}
