import React from 'react'
import firebase from 'firebase'
// components
import UnitSetting from './unit'
import Navbar from '../navbar'
// actions
import SettingsAction from '../../actions/settings'
// stores
import SettingsStore, {_SettingsStore as SettingsStoreClass } from '../../stores/settings'
// scss
import '../../scss/settings.scss'
// types
import Setting from '../../types/setting'

interface Props {}

interface States {
  auth: boolean,
  loadingAuth: boolean,
  setting: Setting | null
}


export default class Settings extends React.Component <Props, States> {
  constructor (props: Props) {
    super(props)
    this.state = {
      auth: false,
      loadingAuth: true,
      setting: null
    }
  }

  componentDidMount = () => {
    firebase.auth().onAuthStateChanged(this.onAuthStateChanged.bind(this))
    SettingsStore.on('change', this.onChangeSetting)
  }

  onAuthStateChanged () {
    const user = firebase.auth().currentUser
    this.setState({
      auth: !!user,
      loadingAuth: false
    })
    if (user) {
      SettingsAction.load(user.uid)
    }
  }

  onChangeSetting = (store: SettingsStoreClass) => {
    if (!store.setting()) {
      // 初期設定を作成
      SettingsAction.init()
      return
    }
    this.setState({setting: store.setting()})
  }

  render () {
    if (!this.state.setting) return null
    return (
      <>
      <Navbar />
        <div className='settings'>
          <div className='container'>
            <h4>Settings</h4>
              <form>
                <div className='form-group'>
                  <UnitSetting setting={this.state.setting} />
                </div>
              </form>
          </div>
        </div>
      </>
    )
  }
}
