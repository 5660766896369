import dispatcher from '../dispatcher'
import firebase from 'firebase'
import Pack from '../types/pack'
const dispatchType = 'change-pack'
const db = firebase.firestore()

let packAction = {
  async update (packId: string, newName: string) {
    await db.collection('packs').doc(packId).update({
      name: newName
    })
    dispatcher.dispatch({ type: dispatchType, packId })
  },

  async duplicate (pack: Pack) {
    const newPack = {
      id: '',
      data: {
        name: pack.data.name + ' - Copy',
        created_at: firebase.firestore.FieldValue.serverTimestamp(),
        user_id: pack.data.user_id
      }
    }
    const docRef = await db.collection('packs').add(newPack.data)
    dispatcher.dispatch({ type: 'duplicate-pack', packId: docRef.id })
  },

  async delete (packId: string) {
    await db.collection('packs').doc(packId).delete()
  },

  load (packId: string) {
    dispatcher.dispatch({ type: dispatchType, packId })
  }
}

export default packAction
