import dispatcher from '../dispatcher'
import firebase from 'firebase'
import Gear from '../types/gear'
const db = firebase.firestore()

export let GearsAction = {

  load () {
    dispatcher.dispatch({type: 'change-gears'})
  },

  loadWithUserId (userId: string) {
    dispatcher.dispatch({type: 'load-with-userid', userId: userId})
  },

  async update (gear: Gear) {
    await db.collection('gears').doc(gear.id!).update(gear.data)
    dispatcher.dispatch({type: 'change-gears'})
  },

  async updateGears (gears: Gear[], dispatchType: string) {
    const promises = gears.map((gear) => {
      return db.collection('gears').doc(gear.id!).update(gear.data)
    })
    await Promise.all(promises)
    dispatcher.dispatch({type: dispatchType})
  },

  async add (gear: Gear) {
    const doc = await db.collection('gears').add(gear.data)
    dispatcher.dispatch({type: 'change-gears'})
    return doc.id
  },

  async delete (gearId: string) {
    await db.collection('gears').doc(gearId).delete()
    dispatcher.dispatch({type: 'change-gears'})
  }
}

export default GearsAction
