import {EventEmitter2 as EventEmitter} from 'eventemitter2'
import dispatcher from '../dispatcher'

const DragStore = new EventEmitter()
DragStore.dispatchToken = dispatcher.register(async (payload) => {
  if (payload.type === 'drag-start') {
    DragStore.emit('drag-start')
  } else if (payload.type === 'drag-end') {
    DragStore.emit('drag-end')
  } else if (payload.type === 'drag-table-row-start') {
    DragStore.emit('drag-table-row-start')
  } else if (payload.type === 'drag-table-row-end') {
    DragStore.emit('drag-table-row-end')
  }
})

export default DragStore
