import React, { Component } from 'react';
import firebase from 'firebase'
import firebaseui from 'firebaseui'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import { Redirect } from 'react-router-dom'
import Helmet from 'react-helmet'
import '../../scss/landing/landing.scss'

export default class Landing extends Component {

  constructor (props) {
    super(props)
    this.state = {
      auth: !!firebase.auth().currentUser,
      loadingAuth: true
    }
  }

  componentDidMount (){
    firebase.auth().onAuthStateChanged(this.onAuthStateChanged.bind(this))
    window.gtagPageview({ page_path: 'top', page_location: window.location.href })
  }

  onSuccessSignIn () {
    // 別のURLにリダイレクトしない場合はfalseを返す必要がある
    return true
  }

  onFailureSignIn (error) {
    alert('fail to sign in')
  }

  onAuthStateChanged () {
    this.setState(
      {
        auth: !!firebase.auth().currentUser,
        loadingAuth: false
      }
    )
  }

  render () {
    let uiConfig = {
     callbacks: {
       signInSuccessWithAuthResult: this.onSuccessSignIn,
       signInFailure: (error) => this.onFailureSignIn(error),
       uiShown: this.props.uiShown
     },
     signInSuccessUrl: '/packs',
     signInFlow: 'popup',
     // メールでのログインに必要
     credentialHelper: firebaseui.auth.CredentialHelper.NONE,
     signInOptions:[
       firebase.auth.GoogleAuthProvider.PROVIDER_ID,
       firebase.auth.EmailAuthProvider.PROVIDER_ID
     ]
    }

    const { lang } = this.props

    return (
      <>
        <Helmet>
          <title>{contents.title[lang]}</title>
           <meta name='description' content={contents.description[lang]} />
        </Helmet>
        {
          this.state.auth &&
          <Redirect to='/packs' />
        }
        {
          !this.state.auth && !this.state.loadingAuth &&
            <div className='landing'>
              <Navbar lang={lang} />
              <Hero lang={lang} uiConfig={uiConfig} />
              <Features lang={lang} />
              <Footer />
            </div>
        }
      </>
    )
  }
}

const Navbar = (props) => {
  return (
    <nav className='lp-navbar'>
      <div className='lp-container lp-navbar__container'>
        GearDojo
      </div>
    </nav>
  )
}

const Hero = (props) => {
  const { lang, uiConfig } = props
  return (
    <div className='hero'>
      <div className='hero__overlay' />
      <div className='lp-container'>
        <div className='lp-row hero__row'>
          <div className='hero__left'>
            <h1>{contents.heading[lang]}</h1>
            <div className='lead'>
              {contents.lead[lang]}
            </div>
            <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
          </div>
          <div className='hero__right'>
            <div className='hero__img'>
              <img src={contents.imageUrl[lang]} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Features = (props) => {
  const { lang } = props
  const features = contents.features
  return (
    <div className='lp-container features'>
      <h2>{contents.featuresHeading[lang]}</h2>
      <div className='features__row'>
        {
          features.map((feature) => {
            return (
              <div className='features__col'>
                <Feature imageUrl={feature.imageUrl} title={feature.title[lang]} desc={feature.desc[lang]} />
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

const Feature = (props) => {
  const { imageUrl, title, desc } = props
  return (
    <div className='feature'>
      <h4 className='feature__title'>{title}</h4>
      <img className='feature__image' src={imageUrl} />
      <div className='feature__desc'>
        {desc}
      </div>
    </div>
  )
}

const Footer = () => {
  return (
    <footer>
      © 2021 Takeru Yoshihara
    </footer>
  )
}

const contents = {
  title: {
    ja: 'GearDojo | ULハイクに特化したギアリスト作成ツール',
    en: 'GearDojo | Gear list creation tool specialized for UL hike'
  },
  description: {
    ja: 'GearDojoはウルトラライトハイキングに特化したギアリスト作成ツールです。重量計算やシェア機能など、より有意義なハイキングを実現するための機能を備えています。',
    en: 'GearDojo is an inventory listing service specializing in ultra-light hiking. It is equipped with functions such as weight calculation and sharing function to realize more meaningful hiking.'
  },
  imageUrl: {
    ja: 'https://i.gyazo.com/222dc2687e2a533cf9283e53e3ddc067.jpg',
    en:'https://i.gyazo.com/8d17e485191355e622ab2bae0278cc24.jpg'
  },
  heading: {
    ja: 'ULハイクに特化したギアリスト作成ツール',
    en: 'Gear list creation tool specialized for UL hike'
  },
  lead: {
    ja:'GearDojoはウルトラライトハイキングに特化したギアリスト作成サービスです。重量計算やシェア機能など、有意義なハイキングを実現するための機能を備えています。',
    en:'GearDojo is an inventory listing service specializing in ultra-light hiking. It is equipped with functions such as weight calculation and sharing function to realize more meaningful hiking.'
  },
  button: {
    ja: '今すぐ始める',
    en: 'Try it for free'
  },
  featuresHeading: {
    ja: 'GearDojoの機能',
    en: 'Features'
  },
  features: [
    {
      title: {
        ja: '重量計算',
        en: 'Weight calculation'
      },
      desc: {
        ja: 'ギアを追加すると、自動で重量が計算されます。重量はベースウェイト、パックウェイト、トータルウェイトの全てを確認できます。',
        en: 'When you add your belongings, the weight will be calculated automatically. You can check the weight of the base weight, pack weight, and total weight.'
      },
      imageUrl: 'https://i.gyazo.com/a4444d88fb4d1929e24376f66137fde1.png'
    },
    {
      title: {
        ja: '共有機能',
        en: 'Sharing'
      },
      desc: {
        ja: 'SNSやチャットでリストを共有しましょう。他の人にとってあなたのリストはとても価値ある情報です。あなた自身が有益なフィードバックを得ることもあるでしょう。',
        en: 'Share your lists on social media and chat. Your list is very valuable information for beginners. You may also get useful feedback.'
      },
      imageUrl: 'https://i.gyazo.com/768fff596ca4c82ceab21859722049a2.png'
    },
    {
      title: {
        ja: 'PCでもモバイルでも',
        en: 'Any device'
      },
      desc: {
        ja: 'GearDojoはPCとモバイルの両方で操作が可能です。パッキングの際の持ち物チェックや、仕事の合間のリスト作成も簡単です。',
        en: 'You can use GearDojo on both of desktop and mobile device. It makes easy to check your belongings when packing and create a list between work.'
      },
      imageUrl: 'https://i.gyazo.com/73ad85071f86f7d922f144d4f798925b.png'
    }
  ]
}
