import React from 'react'
import { Dropdown } from 'react-bootstrap'
import PacksAction from '../actions/packs'
import PacksStore from '../stores/packs'
import Pack from '../types/pack'
import '../scss/pack-select-button.scss'
import cx from 'classnames'

interface Props {
  selectedPack: Pack
}

interface State {
  packs: Pack[]
}

export default class PackSelectButton extends React.Component <Props, State> {
  constructor (props: Props) {
    super(props)

    this.state = {
      packs: []
    }
  }

  componentDidMount () {
    PacksStore.on('change', this.onChangePacks)
    PacksAction.load()
  }

  componentWillUnmount () {
    PacksStore.off('change', this.onChangePacks)
  }

  onChangePacks = (store: any) => {
    this.setState({ packs: store.packs() })
  }

  render () {
    return (
      <Dropdown>
        <Dropdown.Toggle as={PackSelectToggle} id='pack-select-button'>
          <i className='fa fa-sort'/>
        </Dropdown.Toggle>
        <Dropdown.Menu className='pack-select-menu'>
          {
            this.state.packs.map((pack) => {
              const selected = pack.id === this.props.selectedPack.id
              return (
                <Dropdown.Item
                  href={`/pack/${pack.id}`} className={cx('pack-select-menu-item',{ selected: selected })}
                  key={pack.id}>
                  {pack.data.name}
                  {
                    selected &&
                      <i className='fa fa-check' />
                  }
                </Dropdown.Item>
              )
            })
          }
        </Dropdown.Menu>
      </Dropdown>
    )
  }
}

interface PackSelectToggleProps {
  children: React.ReactNode,
  onClick: any,
  className: string
}

class PackSelectToggle extends React.Component <PackSelectToggleProps> {
  render () {
    return (
      <button className='btn pack-header-btn d-none d-sm-block' onClick={this.props.onClick}>
        {this.props.children}
      </button>
    )
  }
}
