import React, {Component} from 'react'
import GearCreateForm from './gear-create-form'
import GearLibrary from './gear-library'
import '../scss/gear-nav.scss'

interface Props {
  gears: any
  setting: any
}

interface States {
  filteredGears: any
}

export default class GearNav extends Component <Props, States> {

  private filterRef = React.createRef<HTMLInputElement>()
  constructor (props: Props) {
    super(props)
    this.state = {
      filteredGears: []
    }

    this.filterRef = React.createRef()
  }

  onChangeFilter (e: any) {
    const keyword = e.target.value
    this.setState({ filteredGears: this.filterGears(this.props.gears, keyword) })
  }

  componentDidMount = () => {
    this.setState({filteredGears: this.props.gears})
  }

  componentWillReceiveProps (nextProps: any) {
    this.setState({filteredGears: nextProps.gears})
  }

  filterGears (gears: any, keyword: string) {
    if (!keyword) return gears
    return gears.filter ((gear: any) => {
      // タイトルに含まれる
      if (gear.data.name.indexOf(keyword) >= 0) return true
      // タグに含まれる
      let tagIncludesKeyword = false
      if (!gear.tags) return false
      gear.tags.forEach((tag: any) => {
        if (tag.indexOf(keyword) >= 0) tagIncludesKeyword = true
      })
      return tagIncludesKeyword
    })
  }

  render () {
    const { filteredGears } = this.state
    const { setting } = this.props

    return (
      <div className='gear-nav'>
        <h4>Gear inventory</h4>
          <div className='gear-nav-search'>
            <div className='gear-nav-search-icon'>
              <i className='fa fa-search' />
            </div>
            <input className='form-control' type='text' placeholder='Search by keywords or tags'  ref={this.filterRef} onChange={this.onChangeFilter.bind(this)} />
          </div>
          <GearCreateForm />
          <GearLibrary gears={filteredGears} setting={setting} />
      </div>
    )
  }
}
