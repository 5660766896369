import React, {Component} from 'react'
import PackAction from '../actions/pack'
import Pack from '../types/pack'
import {Modal, Button} from 'react-bootstrap'
import {Redirect} from 'react-router-dom'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

interface Props {
  pack: Pack
  className: string
  children: any
}

interface States {
  showModal: boolean
}

export default class PackDeleteButton extends Component <Props, States> {

  constructor (props: Props) {
    super(props)

    this.state = {
      showModal: false
    }
  }

  onClick () {
    this.setState({ showModal: true })
  }

  onHideModal () {
    this.setState({ showModal: false })
  }

  render () {
    return (
      <>
        <OverlayTrigger
          placement='bottom'
          overlay={
            <Tooltip id='hoge'>
               Delete backpack
            </Tooltip>
          }
        >
        <a onClick={this.onClick.bind(this)} className={this.props.className}>
          {this.props.children}
        </a>
        </OverlayTrigger>
        <PackDeleteModal
          pack={this.props.pack}
          onHide={this.onHideModal.bind(this)}
          show={this.state.showModal}
        />
      </>
    )
  }
}



interface PackDeleteModalProps {
  pack: Pack
  show: boolean
  onHide: () => void
}

interface PackDeleteModalStates {
  deleted: boolean
}

class PackDeleteModal extends Component <PackDeleteModalProps, PackDeleteModalStates>{


  constructor (props: PackDeleteModalProps) {
    super(props)
    this.state = {
      deleted: false
    }
  }

  async delete () {
    await PackAction.delete(this.props.pack.id)
    alert(`${this.props.pack.data.name} was deleted`)
    this.setState({ deleted: true })
  }

  render () {
    const pack = this.props.pack
    return (
      <>
        {
          this.state.deleted &&
          <Redirect to='/packs' />
        }
        <Modal className='pack-delete-modal' show={this.props.show} onHide={this.props.onHide}>
          <Modal.Body>
            Are you sure you want to delete <strong>{pack.data.name}</strong> ?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="light" onClick={this.props.onHide}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.delete.bind(this)}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}
