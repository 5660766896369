import React, {Component} from 'react'
import '../scss/gear-library.scss'
import GearLibraryItem from './gear-library-item'

interface Props {
  setting: any
  gears: any
}

export default class GearLibrary extends Component <Props> {
  constructor (props: Props) {
    super(props)
  }

  render () {
    const {setting} = this.props

    return (
      <>
        {
          this.props.gears.length === 0 &&
            <div className='gear-library-placeholder'>
              There are no gears yet. Add gears and drag and drop them into the backpack category.
            </div>
        }
        <ul className='gear-library'>
          {
            this.props.gears.map((gear: any) => {
              return <GearLibraryItem gear={gear} setting={setting} key={gear.id} />
            })
          }
        </ul>
      </>
    )
  }
}
