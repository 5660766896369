import React from 'react'
import CategoryTable from './category-table'
import Gear from '../../types/gear'
import Category from '../../types/category'
import Pack from '../../types/pack'
import '../../scss/pack-table.scss'
import * as helper from '../../helpers/helper'

interface Props {
  pack: Pack,
  categories: Category[]
  allGears: Gear[]
  editable?: boolean
  setting: any
}

export default class PackTable extends React.Component <Props> {

  renderCategories (pack: Pack, categories: Category[], editable: boolean, setting: any) {
    return (
      categories.map((category: Category) => {
        return(
          <CategoryTable allGears={this.props.allGears} category={category} pack={pack} editable={editable} setting={setting}
          key={category.id}
           />
        )
      })
    )
  }

  render () {
    const { pack, categories, editable, setting } = this.props
    if (!pack || !categories) return null
    return (
      <div className='pack-table'>
        {
          categories.length === 0 && editable &&
            <PackCategoryPlaceHolder />
        }
        {this.renderCategories(pack, categories, editable!, setting)}
      </div>
    )
  }
}

const PackCategoryPlaceHolder = () => {
  return (
    <div className='pack-table-category-placeholder'>
      <span>
      There are no categories in this backpack yet. Create categories and add your gears.
      </span>
    </div>
  )
}
