import React, {Component} from 'react'
import UserAction from '../actions/user'
import '../scss/pack-footer.scss'

interface Props{
  pack: any
}

export default class PackFooter extends Component <Props> {
  constructor (props: Props) {
    super(props)
  }

  render () {
    const { pack } = this.props
    console.dir(pack)
    return (
      <div className='pack-footer'>
        Created at {pack.data.created_at.toDate().toLocaleString()}
      </div>
    )
  }
}
