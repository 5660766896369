import React from 'react'
import firebase from 'firebase'
import {Redirect} from 'react-router-dom'
// components
import Navbar from './navbar'
import GearNav from './gear-nav'
// stores
import GearsStore, { _GearsStore as GearsStoreClass } from '../stores/gears'
import SettingsStore, {_SettingsStore as SettingsStoreClass } from '../stores/settings'
// actions
import GearsAction from '../actions/gears'
import SettingsAction from '../actions/settings'
//types
import Gear from '../types/gear'
import Setting from '../types/setting'

interface Props {}

interface States {
  auth: boolean,
  loadingAuth: boolean,
  gears: Gear[],
  setting: Setting | null
}


export default class Inventory extends React.Component <Props, States> {
  constructor (props: Props) {
    super(props)
    this.state = {
      auth: false,
      loadingAuth: true,
      gears: [],
      setting: null
    }
  }

  componentDidMount = () => {
    firebase.auth().onAuthStateChanged(this.onAuthStateChanged.bind(this))
    GearsStore.on('change', this.onChangeGears.bind(this))
    SettingsStore.on(('change'), this.onChangeSettings.bind(this))
  }

  componentWillUnmount = () => {
    GearsStore.off('change', this.onChangeGears.bind(this))
    SettingsStore.off(('change'), this.onChangeSettings.bind(this))
  }

  onAuthStateChanged () {
    const user = firebase.auth().currentUser
    this.setState({
      auth: !!user,
      loadingAuth: false
    })
    if (user) {
      GearsAction.load()
      SettingsAction.load()
    }
  }

  onChangeGears (store: GearsStoreClass) {
    this.setState({gears: store.gears()})
  }

  onChangeSettings = (store: SettingsStoreClass) => {
    if (!store.setting()) {
      // 初期設定を作成
      SettingsAction.init()
      return
    }
    this.setState({setting: store.setting()})
  }

  render () {
    return (
      <>
      {
        this.state.loadingAuth &&
        <>loading auth</>
      }
      {
        !this.state.auth && !this.state.loadingAuth &&
        <Redirect to='/' />
      }
      <Navbar />
        <div className='settings'>
          <div className='container'>
          {
            this.state.setting &&
              <GearNav gears={this.state.gears} setting={this.state.setting} />
          }
          </div>
        </div>
      </>
    )
  }
}
