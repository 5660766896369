import dispatcher from '../dispatcher'
import {EventEmitter2 as EventEmitter} from 'eventemitter2'
import firebase from 'firebase'
import Pack from '../types/pack'
const db = firebase.firestore()

export class _PackStore extends EventEmitter {
  private _pack: Pack | null = null
  public dispatchToken: string| undefined = undefined
  constructor () {
    super()
  }

  pack () {
    return this._pack
  }

  initPack (doc: any) {
    return {
      id: doc.id,
      data: doc.data()
    }
  }

  async load (packId: string) {
    const doc = await db.collection('packs').doc(packId).get()
    this._pack = this.initPack(doc)
  }
}

let PackStore = new _PackStore()
PackStore.dispatchToken = dispatcher.register(async (payload: any) => {
  if (payload.type === 'change-pack') {
    await PackStore.load(payload.packId)
    PackStore.emit('change', PackStore)
  } else if (payload.type === 'duplicate-pack') {
    PackStore.emit('duplicate', payload.packId)
  }
})

export default PackStore
