import {EventEmitter2 as EventEmitter } from 'eventemitter2'
import dispatcher from '../dispatcher'
import firebase from 'firebase'
import Category from '../types/category'
const db = firebase.firestore()

export class _CategoriesStore extends EventEmitter {
  private _categories: Category[] = []
  public dispatchToken: string | null = null

  constructor () {
    super()
  }

  initCategory (doc: any) {
    return {
      id: doc.id,
      data: doc.data()
    }
  }

  categories () {
    return this._categories
  }

  async load (packId: string) {
    try {
      const data = await db.collection('categories').where('pack_id', '==', packId).orderBy('created_at', 'desc').get()
      this._categories = data.docs.map((doc) => {
        return this.initCategory(doc)
      })
    } catch (e) {
      console.log(e)
    }
  }
}

let CategoriesStore = new _CategoriesStore()
CategoriesStore.dispatchToken = dispatcher.register( async (payload: any) => {
  if (payload.type === 'change-categories') {
    await CategoriesStore.load(payload.item.packId)
    CategoriesStore.emit('change', CategoriesStore)
  } else if (payload.type === 'duplicate-categories') {
    CategoriesStore.emit('duplicate', payload.item.ids)
  }
})

export default CategoriesStore
