import firebase from 'firebase'

// 環境によって利用するfirebaseのプロジェクトを変えている。
// データベースからホスティング先まで丸ごと変更しているので、
// staging環境のように、ホスティングはproductionとは別だがデータベースは共有,
// みたいな環境の作り方はまだ分からない 2019/6/15

function initFirebase () {
  let configProduction = {
    apiKey: 'AIzaSyCoWOeQkJyAbOEXP5Cx_-iipFOuEF2P2SY',
    authDomain: 'gear-list-hub.firebaseapp.com',
    databaseURL: 'https://gear-list-hub.firebaseio.com',
    projectId: "gear-list-hub",
    storageBucket: 'gear-list-hub.appspot.com',
    messagingSenderId: '111194835549',
    appId: '1:111194835549:web:5c225b235367d36f'
  };

  let configDev = {
    apiKey: "AIzaSyCpBDT7jR5RaqK0vl1D7kfdbIerFKVlaFA",
    authDomain: "gear-list-hub-dev.firebaseapp.com",
    databaseURL: "https://gear-list-hub-dev.firebaseio.com",
    projectId: "gear-list-hub-dev",
    storageBucket: "gear-list-hub-dev.appspot.com",
    messagingSenderId: "18400749264",
    appId: "1:18400749264:web:4ab7f8a5d5946776"
  }

  const config = process.env.NODE_ENV === 'development' ? configDev : configProduction
  firebase.initializeApp(config);
}

initFirebase()
