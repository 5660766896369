import dispatcher from '../dispatcher'
import firebase from 'firebase'
import Pack from '../types/pack'
const dispatchType = 'change-packs'

const db = firebase.firestore()

let packsAction = {
  async create (pack: Pack) {
    await db.collection('packs').add(pack.data)
    dispatcher.dispatch({type: dispatchType})
  },

  async update (pack: Pack) {
    await db.collection('packs').doc(pack.id).update(pack.data)
    dispatcher.dispatch({ type: dispatchType })
  },

  load () {
    dispatcher.dispatch({ type: dispatchType })
  },

  select (pack: Pack) {
    dispatcher.dispatch({type: 'select-pack', item: {pack: pack}})
  }
}

export default packsAction
