import dispatcher from '../dispatcher'
import {EventEmitter2 as EventEmitter} from 'eventemitter2'
import firebase from 'firebase'
import Pack from '../types/pack'
const db = firebase.firestore()

class _PacksStore extends EventEmitter {
  private _packs: Pack[] = []
  public dispatchToken: string | undefined = undefined

  constructor () {
    super()
  }

  packs() {
    return this._packs
  }

  initPack (doc: any) {
    return {
      id: doc.id,
      data: doc.data()
    }
  }

  async load () {
    const user = firebase.auth().currentUser
    if (!user)　return
    const data = await db.collection('packs').where('user_id', '==', user.uid).orderBy('created_at', 'desc').get()
    const packs = data.docs.map((doc) => {
      return this.initPack(doc)
    })
    this._packs = packs
  }
}

let PacksStore = new _PacksStore()
PacksStore.dispatchToken = dispatcher.register(async (payload: any) => {
  if (payload.type === 'change-packs') {
    await PacksStore.load()
    PacksStore.emit('change', PacksStore)
  }
  if (payload.type === 'select-pack') {
    PacksStore.emit('select', payload.item.pack)
  }
})

export default PacksStore
